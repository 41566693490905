import React from 'react';
import { Input as InputWrapper } from 'antd';
import Typography from '../Typography';

import './Input.less';

export default function Input({ type, size, disabled, onChange, value, placeholder, required, status, errorMessage, regexReplace }) {
  return (
    <div className="fanthon-input">
      <Typography type="h9" style={{ display: 'flex', alignItems: 'center' }}>
        {required 
          ? <div style={{ width: '5px', height: '5px', borderRadius: '50%', background: '#D74039' }}/> 
          : <></> 
        }
        {placeholder}
      </Typography>
      <InputWrapper
        allowClear
        disabled={disabled}
        type={type}
        size={size}
        status={status}
        placeholder={placeholder}
        onInput={(event) => {
          event.preventDefault();
          event.stopPropagation();
          event.target.value = event.target.value.replace(new RegExp(regexReplace), '');
        }}
        onChange={(event) => onChange(event.target.value)}
        defaultValue={value}
      />
      {errorMessage && (
        <div>
          <Typography
            type="h9"
            style={{ color: '#D74039' }}
          >
            {errorMessage}
          </Typography>
        </div>
      )}
    </div>
  );
}