const NUMBER_OF_MONTHS = {
  ONCE: {
    MONTH: 1,
  },
  MONTH: {
    MONTH: 1,
    QUARTER: 3,
    SEMESTER: 6,
    YEAR: 12,
  },
  QUARTER: {
    YEAR: 4,
    SEMESTER: 2,
  }
};


export function getAmountOfMonths(value, type) {
  return (NUMBER_OF_MONTHS.MONTH?.[type] || 1) * value;
}

export function getAmountOfInstallments(frequencyPayment, value, type) {
  return (NUMBER_OF_MONTHS?.[frequencyPayment]?.[type] || 1) * value;
}

export function getLocalePeriod(period) {
  const localePeriod = { 
    'MONTH': 'MÊS',
    'MONTHS': 'MESES',
    'QUARTER': 'TRIMESTRE',
    'QUARTERS': 'TRIMESTRES',
    'SEMESTER': 'SEMESTRE',
    'SEMESTERS': 'SEMESTRES',
    'YEAR': 'ANO',
    'YEARS': 'ANOS',
  }

  return localePeriod[period];
}