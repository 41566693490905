import axios from 'axios';
const { BACKEND_URL, DEBUG } = process.env;

axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
const Api = axios.create({
  baseURL: BACKEND_URL,
  timeout: 0,
  withCredentials: true,
  mode: 'no-cors',
  crossDomain: true,
  credentials: 'same-origin',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Allow-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Headers': 'x-requested-with',
  },
});

const company = async (slugName) => {
  if (!slugName) return;
  return !DEBUG && Api.get(`/link/${slugName}`);
};

const service = async (companyId, linkId) => {
  if (!companyId || !linkId) return;
  return !DEBUG && Api.get(`/link/${companyId}/${linkId}`);
};

const customer = async (companyId, linkId, data) => {
  if (!companyId || !linkId || !data) return;
  return !DEBUG && Api.post(`/link/${companyId}/${linkId}/customer`, data);
};

const create = async (companyId, linkId, data) => {
  if (!companyId || !linkId || !data) return;
  return !DEBUG && Api.post(`/link/${companyId}/${linkId}/create`, data);
};

const valid = async (companyId, linkId, taxNumber) => {
  if (!companyId || !linkId || !taxNumber) return;
  return (
    !DEBUG &&
    Api.get(`/link/${companyId}/${linkId}/customer?taxNumber=${taxNumber}`)
  );
};

const contract = async (companyId, contractId) => {
  if (!companyId || !contractId) return;
  return !DEBUG && Api.get(`/link/${companyId}/contract/${contractId}`);
};

const updateContract = async (companyId, contractId, data) => {
  if (!companyId || !contractId || !data) return;
  return (
    !DEBUG &&
    Api.patch(
      `/link/${companyId}/contract/${contractId}/credit-card`,
      data,
    )
  );
};

const createCard = async ({
  tenant_id,
  company_id,
  wallet_id,
  contact_id,
  data,
}) => {
  return (
    !DEBUG &&
    Api.post(`/link/${tenant_id}/${company_id}/credit-card/create`,
      { 
        ...data,
        wallet_id,
        contact_id
      }
    )
  );
};

const Http = {
  customer,
  create,
  service,
  company,
  valid,
  contract,
  updateContract,
  createCard,
};

export default Http;
