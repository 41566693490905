import { Row, Col, Card } from '../index';
import "./CardBox.less";

export default function CardBox({ children, className }) {
  return (
    <Row className="containerCardBox" justify="center">
      <Col className="cardLayout">
        <Card className={className}>{children}</Card>
      </Col>
    </Row>
  );
}
