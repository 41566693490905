const STORAGE_KEY = 'fanthon-web-link';
export const setItem = async (key, value) => {
	try {
		const newValue = JSON.stringify(value);
		if (newValue) await window.localStorage.setItem(`${STORAGE_KEY}_${key}`, newValue);
		return true;
	} catch (error) {
		console.error(error);
		return false;
	}
};

export const getItem = async (key) => {
	try {
		const response = await window.localStorage.getItem(`${STORAGE_KEY}_${key}`);
		return response && JSON.parse(response);
	} catch (error) {
		console.error(error);
		return null;
	}
};

export const deleteItem = async (key) => {
	try {
		await window.localStorage.removeItem(`${STORAGE_KEY}_${key}`);
		return true;
	} catch (error) {
		console.error(error);
		return false;
	}
};

export const clearItem = async () => {
	try {
		await window.localStorage.clear();
		return true;
	} catch (error) {
		console.error(error);
		return false;
	}
};
