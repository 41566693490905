export const DEFAULT = {
  tenant_id: null,
  company_id: null,
  configurations: {},
  customer: {
    id: null,
    type: 'PP',
  },
  contract: {
    receiptDay: null,
    invoiceDay: null,
    paymentMethod: null,
    vigenceType: null,
    initialDate: null,
    finalDate: null,
    customFields: {
      meansOfContact: [
        // 'email',
        // 'call',
        // 'whatsapp',
        // 'sms',
      ],
    },
    typeSlip: null, // boleto / pix / credit_card
    encryptedCard: null,
  },
  contract_items: [],
  witness: {
    fullName: null,
    taxNumber: null,
    email: null,
  }
};